import  {createAxiosInstance} from "@/common/axiosInstance";

const BASE_PATH = 'search_v1';

const searchFetcher = (req=null) => {

  const axiosInstance1 = createAxiosInstance(req, BASE_PATH, true);

  return axiosInstance1;
};

export default searchFetcher;
