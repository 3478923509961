import stl from "./Suggestions.module.css";
import { unbxdAnalytics } from "@/redux/actions/unbxdActions";
import { useLocation } from "react-router-dom";
import { serverUrl } from "@globalConfig";

const Suggestions = ({ suggestionsData, searchTerm, handleTextSearch }) => {
  const location = useLocation();


  const suggestions = suggestionsData.filter((product) => product.autosuggest);

  const renderer = (suggestion) => {
    if (suggestion?.toLowerCase().includes(searchTerm.toLowerCase())) {
      const regex = new RegExp(`(${searchTerm})`, "gi");
      const parts = suggestion.split(regex);

      return (
        <div>
          {parts.map((part, index) =>
            regex.test(part) ? (
              <span key={index} className={stl.highlighted}>
                {part}
              </span>
            ) : (
              <span key={index}>{part}</span>
            )
          )}
        </div>
      );
    }

    return (
      <div>
        <span>{suggestion}</span>
      </div>
    );
  };

  return (
    <ul className={stl.SuggestionsWrapper}>
      {suggestions?.map((suggestion) => {
        return (
          <li
            onClick={(e) => {
              handleTextSearch(e, suggestion.autosuggest);
                unbxdAnalytics({
                  action: "search",
                  autosuggest_type: "KEYWORD_SUGGESTION",
                  autosuggest_suggestion: suggestion.autosuggest,
                  field_name: null,
                  field_value: null,
                  internal_query: searchTerm,
                  query: suggestion.autosuggest || "",
                  url: serverUrl + location.pathname + location.search,
                })
            }}
            key={suggestion.autosuggest}
          >
            {renderer(suggestion.autosuggest)}
          </li>
        );
      })}
    </ul>
  );
};

export default Suggestions;
