import { useEffect, useState } from "react";
import useDebounce from "./useDebounce";
import { useLocation } from "react-router-dom";
import { unbxdAnalytics } from "@/redux/actions/unbxdActions";
import { serverUrl } from "@globalConfig";
import { useLang } from "../Contexts/LanguageContext";
import useIsMobile from "@hooks/useIsMobile";
import { api__getUnbxdSuggestions } from "@/apis/search/search";
import useHelpers from "@hooks/useHelpers";
var useUnbxdTextSearch = function useUnbxdTextSearch(showSuggestions) {
  var _useState = useState(""),
    searchTerm = _useState[0],
    setSearchTerm = _useState[1];
  var _useState2 = useState([]),
    searchTermSuggestions = _useState2[0],
    setSearchTermSuggestions = _useState2[1];
  var isMobile = useIsMobile();
  var location = useLocation();
  var _useHelpers = useHelpers(),
    navigateTo = _useHelpers.navigateTo;
  var debouncedSearchTerm = useDebounce(searchTerm);
  var handleSearchTermChange = function handleSearchTermChange(e) {
    setSearchTerm(e.target.value);
  };
  var handleTextSearch = function handleTextSearch(e, suggestion) {
    e.preventDefault();
    if (!(searchTerm !== null && searchTerm !== void 0 && searchTerm.trim().length)) return;
    if (e.key !== "Enter" && isMobile && !suggestion) return;
    if (showSuggestions) showSuggestions(false);
    setSearchTermSuggestions([]);
    if (suggestion) {
      window.searchSuggestion = suggestion;
    } else {
      window.searchSuggestion = null;
    }
    if (!suggestion) unbxdAnalytics({
      action: "search",
      query: suggestion || searchTerm || "",
      url: serverUrl + location.pathname + location.search
    });
    navigateTo("/filters?text=".concat(suggestion ? suggestion : searchTerm));
  };
  var lang = useLang();
  useEffect(function () {
    if (!debouncedSearchTerm.trim().length) {
      setSearchTermSuggestions([]);
      return;
    }
    api__getUnbxdSuggestions(lang, debouncedSearchTerm).then(setSearchTermSuggestions);
  }, [debouncedSearchTerm]);
  return {
    searchTerm: searchTerm,
    searchTermSuggestions: searchTermSuggestions,
    handleSearchTermChange: handleSearchTermChange,
    handleTextSearch: handleTextSearch,
    setSearchTerm: setSearchTerm,
    setSearchTermSuggestions: setSearchTermSuggestions
  };
};
export default useUnbxdTextSearch;