import stl from "../../HomePage.module.css";
import LinksToSearchV2 from "../LinksToSearch/LinksToSearchV2";
import useHelpers from "@hooks/useHelpers";
import {useTranslation} from "@i18n";

export default function SearchByRangeContainer(props) {
    const {t} = useTranslation(["web_home"]);
    const { createUrl } = useHelpers();
    const {
        price_ranges,
        odometer_ranges,
    } = props.data;
    return (<>
        <h2 className="px-8 mx-auto text-[#154F9C] text-lg font-bold leading-[32px] md:text-xl ">
            <div className="border-t border-t-[#E2E2E2] border-solid rounded-[2px] mt-4 mb-4"></div>
            {t("تصفح السيارات حسب السعر أو الممشى", {ns: "web_home"})}
        </h2>
        <div
            className={[
                "m-show",
                stl.CustomContainer,
                stl.undoCustomWidth,
            ].join(" ")}>
            <LinksToSearchV2
                moreLink={createUrl(
                    "/filters",
                    {
                        sort: "price"
                    }
                )}
                range
                bgColor="#EFF6FF"
                data={price_ranges}
                fieldName={"price"}
                linkValue="value"
                fromField="from"
                toField="to"
                isNew={props.isNew}
                isPrice
            />
        </div>


        {!props.isNew && (
            <div
                className={[
                    "m-show",
                    stl.CustomContainer,
                    stl.undoCustomWidth,
                ].join(" ")}>
                <LinksToSearchV2
                    moreLink={createUrl(
                        "/filters",
                        {
                            sort: "milage",
                        }
                    )}
                    bgColor="#FCEDE3"
                    data={odometer_ranges }
                    fieldName={"range"}
                    linkValue={"id"}
                    isNew={props.isNew}
                    range={true}
                />
            </div>
        )}


    </>);

}